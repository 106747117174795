import { render, staticRenderFns } from "./productos-carrito.vue?vue&type=template&id=3682cb86&scoped=true"
import script from "./productos-carrito.vue?vue&type=script&lang=js"
export * from "./productos-carrito.vue?vue&type=script&lang=js"
import style0 from "./productos-carrito.vue?vue&type=style&index=0&id=3682cb86&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3682cb86",
  null
  
)

export default component.exports